<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- 大图 -->
    <div class="dt">
      <div class="dt_text">
        <div class="text1">彼思生产ERP</div>
        <div class="text2">生产管理自动化，功能齐全</div>
      </div>
    </div>

    <div class="question-box">
      <div class="question-head">解决企业以下问题</div>
      <div class="question">
        <div class="list-box lefttop">
          <div class="question-title">决策慢</div>
          <div class="question-desc">
            管理者无法及时掌握一手准确信息，导致决策需靠经验，加大决策风险
          </div>
        </div>
        <div class="list-box righttop">
          <div class="question-title">管控难</div>
          <div class="question-desc">
            项目周期长，涉及到立项、投标、合同、设计、
            采购、施工、发票等过程，管理环节多、难度大
          </div>
        </div>
        <div class="list-box leftbottom">
          <div class="question-title">利润低</div>
          <div class="question-desc">
            上游产业投资价格压低、建企之间激烈竞争，用工成本、采购成本不断增加，导致利润持续走低
          </div>
        </div>
        <div class="list-box rightbottom">
          <div class="question-title">要求高</div>
          <div class="question-desc">
            质量终身责任制、四流合一、四库一平台等对施工企业信息监管和质量要求提出更高的要求
          </div>
        </div>
      </div>
    </div>

    <!-- 软件流程图 -->
    <div>
      <div class="cpgn_title">功能模块简介</div>
      <img
        src="http://14.116.154.181:8890/bisi_website/gw/erp-lc2.png"
        alt=""
      />
    </div>

    <!-- 系统页面 -->
    <div class="xtym">
      <div class="cpgn_title">系统页面</div>
      <!-- <div class="cpgn_subtitle">彼思系统双端联动</div> -->
      <img
        class="xt-img"
        src="http://14.116.154.181:8890/bisi_website/gw/erp-tp2.png"
        alt=""
      />
    </div>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "retail",
  data() {
    return {
      gntb: [
        {
          index: 1,
          title: "商品管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon01.png",
        },
        {
          index: 2,
          title: "销售管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon02.png",
        },
        {
          index: 3,
          title: "采购管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon03.png",
        },
        {
          index: 4,
          title: "库存管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon04.png",
        },
        {
          index: 5,
          title: "会员管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon05.png",
        },
        {
          index: 6,
          title: "结算管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon06.png",
        },
        {
          index: 7,
          title: "批发管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon07.png",
        },
        {
          index: 8,
          title: "广告管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon08.png",
        },
        {
          index: 9,
          title: "微商城",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon09.png",
        },
        {
          index: 10,
          title: "促销管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon10.png",
        },
        {
          index: 11,
          title: "配送管理",
          url: "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon11.png",
        },
      ],
    };
  },
  components: { Header, Footer },
};
</script>

<style scoped>
.question-box {
  width: 1400px;
  margin: 0 auto;
}
.question-head {
  margin: 50px;
  font-size: 30px;
  color: #293a6c;
}
.question {
  background-image: url("http://14.116.154.181:8890/bisi_website/gw/questionimg2.gif");
  height: 500px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
  position: relative;
}
.question-title {
  width: 150px;
  height: 40px;
  font-size: 28px;
  font-weight: 600;
  color: #057df6;
  line-height: 40px;
  text-align: left;
}
.question-desc {
  width: 353px;
  height: 90px;
  font-size: 16px;
  color: #0a1735;
  text-align: left;
}
.list-box {
  width: 353px;
  height: 130px;
}
.lefttop {
  position: absolute;
  left: 0;
  top: 0;
}
.righttop {
  position: absolute;
  right: 0;
  top: 0;
}
.leftbottom {
  position: absolute;
  left: 0;
  bottom: 0;
}
.rightbottom {
  position: absolute;
  right: 0;
  bottom: 0;
}
/* 大图 */
.dt {
  height: 545px;
  width: 100%;
  background-image: url("http://14.116.154.181:8890/bisi_website/gw/banner_erp.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}
.dt_text {
  margin: 0 auto;
  width: 1100px;
  height: 545px;
  text-align: left;
}
.text1 {
  padding: 163px 0px 30px 0px;
  font-size: 55px;
  color: #ffffff;
   font-family: cursive;
}
.text2 {
  font-weight: 700;
  padding-bottom: 20px;
  font-size: 30px;
  color: #ffffff;
}
.text3 {
  font-size: 22px;
  color: #ffffff;
}

/* 产品功能 */
.cpgn {
  width: 1100px;
  margin: 0px auto;
}
.cpgn_title {
  margin: 50px;
  font-size: 30px;
  color: #293a6c;
}
.cpgn_subtitle {
  color: #b6b6b9;
  padding: 15px 0px 50px 0px;
}
.cpgn_img_left {
  width: 420px;
  height: 400px;
  margin-right: 120px;
}
.cpgn_img_right {
  width: 420px;
  height: 400px;
  margin-left: 80px;
}

/* 功能图标 */
.gntb {
  width: 1100px;
  height: 450px;
  margin: 0px auto;
}
.gntb ul li {
  width: 106px;
  height: 106px;
  margin: 28px;
  list-style: none;
  float: left;
  text-align: center;
  margin-left: 36px;
}
.gntb ul li p {
  background: #ffffff;
  width: 106px;
  height: 106px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(65, 146, 239, 0.22);
}

.gntb ul li img {
  margin-top: 23px;
  width: 60px;
  height: 60px;
}

/* 会员管理 */
.hygl {
  background-image: url(http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/hyglbanner.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
  height: 700px;
  text-align: center;
}

.hygl_title {
  position: relative;
  color: #293a6c;
  font-size: 30px;
  top: 50px;
}
.hygl_subtitle {
  position: relative;
  color: #b6b6b9;
  font-size: 15px;
  top: 70px;
}

/* 聚合支付 */
.jhzf {
  width: 10100;
  height: 450px;
  margin: 0px auto;
}

.jhzf_title {
  padding: 50px 0px 10px 0px;
  height: 50px;
  color: #293a6c;
  font-size: 30px;
}
.jhzf_subtitle {
  padding-bottom: 70px;
  color: #b6b6b9;
  font-size: 15px;
}

/* 消除浮点 */
.clearfix {
  *zoom: 1;
}

.clearfix:after {
  content: "clear";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}

/* .xt-img{
  width: 1400px;
  height: 762px;
} */
</style>